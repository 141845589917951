import React from "react";
import styled from "styled-components";

const Container = styled.div(props => {
  const { margin = 10 } = props;

  return {
    maxWidth: "50%",
    minWidth: "30rem",
    padding: `${margin}rem 0`,
    margin: "0 auto",

    "@media only screen and (max-width: 1600px)": {
      maxWidth: "60%",
    },
    "@media only screen and (max-width: 1400px)": {
      maxWidth: "70%",
    },
    "@media only screen and (max-width: 1200px)": {
      maxWidth: "80%",
    },
    "@media only screen and (max-width: 1000px)": {
      maxWidth: "90%",
      minWidth: "25rem",
    },
    "@media only screen and (max-width: 900px)": {
      maxWidth: "95%",
    },
  };
});

const ContentContainer = props => {
  return <Container margin={props.margin}>{props.children}</Container>;
};

export default ContentContainer;
