import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
${reset}
  html {
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
  }

  
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-grow: 1;
    overflow-x: hidden;
  }
  

  h1, h2, h3, h4, h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: #1A1A1A;
  }

  h1 {
    line-height: 1.3;
    font-size: 5rem;
    @media only screen and (max-width: 800px) {
    font-size: 4rem;
    }
    @media only screen and (max-width: 600px) {
    font-size: 2.5rem;
    }
    @media only screen and (max-width: 400px) {
    font-size: 1.8rem;
    }
  }


  h2 {
    font-size: 2.5rem;
    @media only screen and (max-width: 800px) {
    font-size: 2.4rem;
    }
    @media only screen and (max-width: 600px) {
    font-size: 2.3rem;
    }
    @media only screen and (max-width: 400px) {
    font-size: 2.2rem;
    }
    @media only screen and (max-width: 300px) {
    font-size: 1.8rem;
    }
  }

  h3 {
    font-size: 2rem;
    @media only screen and (max-width: 800px) {
    font-size: 1.9rem;
    }
    @media only screen and (max-width: 600px) {
    font-size: 1.8rem;
    }
    @media only screen and (max-width: 400px) {
    font-size: 1.7rem;
    }
  }

  li {
    font-size: 1.4rem;
    line-height: 1.4;
  }

  p {
   color: #1A1A1A;
   font-size: 1.6rem;
   line-height: 1.5;
  }
`;

export default GlobalStyles;
