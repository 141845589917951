import React from "react";
import styled from "styled-components";

import CompanyLogo from "../assets/svg/resized_logo.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
`;

const Logo = styled.img`
  max-width: 18rem;
`;

const Header = () => {
  return (
    <Container>
      <Logo src={CompanyLogo} alt="Company Logo" />
    </Container>
  );
};

export default Header;
