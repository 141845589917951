import React from "react";
import styled from "styled-components";

import ListItem from "./listItem";

const StyledList = styled.ul``;

const Title = styled.h3(({ color, size }) => {
  const fontSize = size === "small" ? "1.2rem" : "";
  return {
    color: color,
    marginBottom: "0.3rem",
    fontSize,
  };
});

const List = props => {
  const { title, items, color, titleSize = "default", wrap = "wrap" } = props;

  return (
    <div>
      {title && (
        <Title color={color} size={titleSize}>
          {title}
        </Title>
      )}
      <StyledList color={color}>
        {items.map(item => (
          <ListItem key={item} text={item} color={color} wrap={wrap} />
        ))}
      </StyledList>
    </div>
  );
};

export default List;
