import React from "react";
import styled from "styled-components";

const StyledListItem = styled.li(props => ({
  color: props.color,
  whiteSpace: props.wrap,
}));

const ListItem = props => {
  return (
    <StyledListItem color={props.color} wrap={props.wrap}>
      {props.text}
    </StyledListItem>
  );
};

export default ListItem;
