import React from "react";
import styled from "styled-components";

import Logo from "./logo";
import List from "./list";
import ContentContainer from "../components/contentContainer";
import TrustedPartnerIcon from "../components/trustedPartner";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0d2b4c;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  > * :not(:last-child) {
    margin: 0 2rem 0 0;
    @media only screen and (max-width: 800px) {
      margin: 0 0 2rem 0;
    }
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin: 0;
    align-items: flex-start;
  }
`;

const ContentItemContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InfoText = styled.p`
  color: white;
`;

const CopyrightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  padding: 1.5rem 0 1.5rem 0;
  width: 100%;
`;

const Copyright = styled.p`
  font-weight: 700;
`;

const listData = [
  "Jokikatu 7 A 39",
  "80220, Joensuu",
  "puh. 050 464 4760",
  "ville.kantelinen(at)kantelinen.fi",
];

const billing = [
    "Insinööritoimisto Kantelinen Oy, 2966739-4",
    "Verkkolaskuosoite: 003729667394",
    "Välittäjä: Maventa",
    "Välittäjätunnus: 003721291126",
    "Välittäjätunnus pankkiverkosta lähetettäessä: DABAFIHH"
];

const Footer = () => {
  return (
    <FooterContainer>
      <ContentContainer margin={5}>
        <Logo type="white" />
        <ItemContainer>
          <ContentItemContainer>
            <InfoText>
              Tarjoamme asiakkaillemme ammattitaitoista rakenne- ja
              elementtisuunnittelua saneeraus- ja uudiskohteiden puitteissa
            </InfoText>
            <TrustedPartnerIcon />
          </ContentItemContainer>
          <ContentItemContainer>
            <List
              title="Insinööritoimisto Kantelinen Oy"
              items={listData}
              color="#fff"
            />
          </ContentItemContainer>
          <ContentItemContainer>
            <List items={billing} title="Laskutustiedot" color="#fff" />
          </ContentItemContainer>
        </ItemContainer>
      </ContentContainer>
      <CopyrightContainer>
        <Copyright>&copy; Insinööritoimisto Kantelinen 2022</Copyright>
      </CopyrightContainer>
    </FooterContainer>
  );
};

export default Footer;
