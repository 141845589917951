import React from "react";
import styled from "styled-components";

import WhiteLogo from "../assets/svg/kantelinen-logo-rgb-white.svg";
import WhiteSmallLogo from "../assets/svg/kantelinen-logo-K-white.svg";
import CompanyLogo from "../assets/svg/resized_logo.svg";

const StyledLogo = styled.img`
  height: 4rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1200) {
    height: 3rem;
  }
`;

const Logo = props => {
  const { type } = props;
  let Logo = CompanyLogo;
  if (type === "white") Logo = WhiteLogo;
  if (type === "white-small") Logo = WhiteSmallLogo;

  return <StyledLogo src={Logo} alt="Company Logo" />;
};

export default Logo;
