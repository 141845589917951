import React from "react";
import styled from "styled-components";

import GlobalStyles from "../theme/globalStyles";
import Header from "../components/header";
import Footer from "../components/footer";

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
`;

const Layout = props => {
  return (
    <>
      <GlobalStyles />
      <Container>
        <Header />
        {props.children}
        <Footer />
      </Container>
    </>
  );
};

export default Layout;
