import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Link = styled.a`
  cursor: pointer;
`;

const Icon = styled(Img)`
  max-width: 12rem;
  margin: 2rem 0;
`;

const TrustedPartnerIcon = () => {
  const data = useStaticQuery(graphql`
    query TrustedPartner {
      file(relativePath: { eq: "images/LK_valkoinen_rgb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Link
      href="https://zeckit.com/selvitys/FI/2966739-4?lang=fi"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon
        fluid={data.file.childImageSharp.fluid}
        alt="Luotettava Kumppani -logo"
      />
    </Link>
  );
};

export default TrustedPartnerIcon;
